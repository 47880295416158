<template>
  <el-form
    ref="uploadFormRef"
    :model="uploadForm"
    :rules="rules"
    label-position="top"
    :style="{ 'margin-bottom': !analyze ? '24px' : 0 }"
  >
    <el-form-item :label="t('report.sourceName')" prop="name">
      <el-input v-model="uploadForm.name" />
    </el-form-item>
    <el-form-item :label="t('report.fileTemplate')" prop="type" class="elv-form-item__fileType">
      <el-select
        v-model="uploadForm.slug"
        placeholder=""
        popper-class="elv-source-dialog-popper"
        @change="onChangeTemplate"
      >
        <el-option
          v-for="(item, index) in props.currentData?.platform.csvDriverTemplateList ?? fileTypeOptions"
          :key="index"
          :label="item.name"
          :value="item.slug"
        />
      </el-select>
    </el-form-item>
    <template v-if="analyze">
      <div
        v-if="
          props.currentData?.platform?.csvDriverTemplateList?.length && currentCsvDriverTemplate?.downloadTemplateUrl
        "
        class="elv-source-upload-tip"
      >
        <p>
          {{ t('report.onlyAcceptCSV') }}
          <span @click="onDownloadTemplateFile">{{ t('report.clickDownloadTemplate') }}</span>
        </p>
      </div>
      <div class="elv-source-dialog-fileInfo">
        <div class="elv-source-dialog-fileInfo-file">
          <div>
            <SvgIcon name="csv-file" width="24" height="24" /><span>{{ uploadFile?.name }}</span>
          </div>
          <p @click="onReuploadFile">{{ t('button.reuploadFile') }}</p>
        </div>
      </div>
      <el-form-item :label="t('common.timezone')" prop="timezone" class="elv-source-upload-timezone">
        <el-select
          v-model="uploadForm.timezone"
          filterable
          default-first-option
          popper-class="elv-apply-project-form-popper"
        >
          <el-option
            v-for="(item, index) in timezoneList"
            :key="index"
            :label="`${item.area} (${item.timezone})`"
            :value="item.area"
          />
        </el-select>
      </el-form-item>
    </template>

    <el-form-item v-if="!analyze" prop="Upload">
      <el-upload
        v-show="showUpload"
        ref="uploadRef"
        class="elv-source-dialog-upload"
        drag
        multiple
        :show-file-list="false"
        :data="uploadData"
        :headers="{ Authorization: authorization }"
        :action="`${VITE_API_URL}/entity/${entityId}/uploadCsv`"
        :before-upload="beforeCSVUpload"
        @error="onUploadError"
        @success="onUploadSuccess"
      >
        <SvgIcon name="source-upload" class="elv-source-upload__icon" width="32" height="32" />
        <div class="elv-source-upload__text">
          {{ t('report.dragUploadOrBrowse') }}<br /><span>{{ t('report.uploadCSVMaxSize') }}</span>
        </div>
        <template #tip>
          <div
            v-if="
              props.currentData?.platform?.csvDriverTemplateList?.length &&
              currentCsvDriverTemplate?.downloadTemplateUrl
            "
            class="el-upload__tip"
          >
            <p>
              {{ t('report.onlyAcceptCSV') }}
              <span @click="onDownloadTemplateFile">{{ t('report.clickDownloadTemplate') }}</span>
            </p>
          </div>
        </template>
      </el-upload>
      <div v-if="!showUpload" class="elv-source-upload-parsing-container" :class="{ 'is-error': uploadError }">
        <div
          v-if="
            props.currentData?.platform?.csvDriverTemplateList?.length && currentCsvDriverTemplate?.downloadTemplateUrl
          "
          class="elv-source-upload-tip"
        >
          <p>
            {{ uploadError ? t('report.onlyGenericFormatCSV') : t('report.onlyAcceptCSV') }}
            <span @click="onDownloadTemplateFile">{{ t('report.clickDownloadTemplate') }}</span>
          </p>
        </div>
        <div class="elv-source-upload-content">
          <img v-if="!uploadError" src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
          <SvgIcon v-else name="sources-sync-error" width="32" height="32" />
          <p class="elv-source-upload-parsing-title">
            {{ uploadError ? t('report.parsingFailed') : t('report.parsingFile') }}
          </p>
          <p v-if="!uploadError" class="elv-source-upload-parsing-info">
            {{ uploadFile?.name }} ({{ fileSize }})<span @click="onCancelUpload">{{ t('button.cancel') }}</span>
          </p>
          <p v-else class="elv-source-upload-parsing-info">
            {{ t('report.unableRecognizeYourFile')
            }}<span @click="onReuploadFile">{{ t('button.uploadAnotherFile') }}</span>
          </p>
        </div>
      </div>
    </el-form-item>
  </el-form>

  <div v-if="analyze" class="el-dialog__footer">
    <elv-button
      type="primary"
      round
      class="elv-accounts-dialog-footer__button"
      :loading="submitLoading"
      @click="onClickConnect"
      >{{ t('button.upload') }}</elv-button
    >
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import AccountsApi from '@/api/AccountsApi'
import timezoneList from '@/config/timezone'
import { downloadFile } from '@/lib/download'
import { useEntityStore } from '@/stores/modules/entity'
import type { UploadRawFile, FormInstance, FormRules, UploadProps } from 'element-plus'

const props = defineProps({
  type: {
    type: String,
    default: 'CHAIN'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const { cookies } = useCookies()
const { VITE_API_URL } = import.meta.env

const entityStore = useEntityStore()

const uploadForm: any = reactive({
  type: 'GENERAL',
  slug: '',
  name: '',
  file: '',
  generalType: 'CHAIN',
  timezone: 'UTC',
  platformId: '',
  entityAccountId: ''
})

const submitLoading = ref(false)
const uploadError = ref(false) // 上传失败
const analyze = ref(false) // 是否解析完成
const showUpload = ref(true) // 是否显示上传组件
const uploadRef = ref()
const uploadFormRef = useTemplateRef<FormInstance>('uploadFormRef')
const uploadFile: any = ref<UploadRawFile>()
const fileData: any = ref({
  entityFileId: 0,
  totalLine: 0,
  unrecognizableLine: 0,
  recognizedLine: 0,
  entityAccountId: 0,
  preview: {
    bankAccountList: [],
    walletAccountList: [],
    marketAccountList: [],
    addressList: []
  }
})

const rules = reactive<FormRules>({
  type: {
    required: true,
    trigger: 'blur'
  },
  name: {
    required: true,
    trigger: 'blur',
    message: t('message.pleaseEnterSourceName')
  },
  Upload: {
    required: true,
    trigger: 'blur'
  },
  address: {
    required: true,
    trigger: 'blur'
  },
  timezone: {
    required: true,
    trigger: 'blur'
  }
})
const emit = defineEmits(['updateAccountDetail'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const fileTypeOptions = computed(() => {
  let option: any = []
  if (props.currentData?.platform?.csvDriver === 'CUSTOM') {
    option = [
      {
        name: `${props.currentData?.platform?.name} CSV`,
        slug: 'CUSTOM'
      }
    ]
  } else {
    switch (props.currentData?.platform?.type) {
      case 'CSV':
        option = [
          {
            name: t('report.bankTransactions'),
            slug: 'BANK'
          }
        ]
        break
      case 'EXCHANGE':
        option = [
          {
            name: t('report.exchangeTrade'),
            slug: 'EXCHANGE_TRADE'
          },
          {
            name: t('report.exchangeDeposit'),
            slug: 'EXCHANGE_DEPOSIT'
          },
          {
            name: t('report.exchangeWithdraw'),
            slug: 'EXCHANGE_WITHDRAW'
          }
        ]
        break
      default:
        option = [
          {
            name: t('report.generalTransactions'),
            slug: 'GENERAL'
          }
        ]
        break
    }
  }
  return option
})

const fileSize = computed(() => {
  const units = ['bytes', 'kb', 'm']
  const k = 1024
  const i = Math.floor(Math.log(uploadFile.value?.size) / Math.log(k))
  // eslint-disable-next-line no-unsafe-optional-chaining
  const size = `${parseFloat((uploadFile.value?.size / k ** i).toFixed(2))}${units[i]}`
  return size
})

const authorization = computed(() => {
  return `Bearer ${cookies.get('elv-app-token')}`
})

const uploadData = computed(() => {
  return {
    templateSlug: uploadForm.slug,
    entityAccountId: String(props.currentData?.entityAccountId)
  }
})

const currentCsvDriverTemplate = computed(() => {
  const csvDriverTemplate = find(
    props.currentData?.platform?.csvDriverTemplateList ?? [],
    (item: any) => item.slug === uploadForm.slug
  )
  return csvDriverTemplate
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onReuploadFile = () => {
  analyze.value = false
  uploadError.value = false
  showUpload.value = true
  uploadForm.file = ''
  fileData.value = {
    entityFileId: 0,
    totalLine: 0,
    unrecognizableLine: 0,
    recognizedLine: 0,
    preview: {
      bankAccountList: [],
      walletAccountList: [],
      marketAccountList: [],
      addressList: []
    }
  }
  uploadForm.platformId = ''
  uploadFile.value = undefined
}

const onChangeTemplate = () => {
  onReuploadFile()
}

const onAddCSVSource = async () => {
  try {
    const params: any = {
      type: 'CSV',
      name: uploadForm.name,
      entityAccountId: props.currentData?.entityAccountId,
      entityFileId: fileData.value.entityFileId,
      timezone: uploadForm.timezone,
      providerId: props.currentData?.provider?.providerId
    }
    await AccountsApi.addAccountSource(entityId.value, params)
    emit('updateAccountDetail')
    // const syncData = {
    //   entityId: entityId.value,
    //   sourceName: uploadForm.name,
    //   type: 'CSV'
    // }
    // reportStore.editSourceSync(entityId.value, true, syncData)
    // emitter.emit('onStartSync')
    uploadFormRef.value?.resetFields()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    submitLoading.value = false
  }
}

const onClickConnect = async () => {
  if (!uploadFormRef.value) return
  await uploadFormRef.value.validate((valid: boolean) => {
    if (valid) {
      submitLoading.value = true
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.dataSource?.create
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      onAddCSVSource()
    }
  })
}

const beforeCSVUpload: UploadProps['beforeUpload'] = (rawFile: File) => {
  let status = true
  return uploadFormRef.value
    ?.validateField('name', (isValidate: boolean): any => {
      if (isValidate) {
        if (rawFile.type !== 'text/csv') {
          ElMessage.error('Please upload CSV file!')
          status = false
        }
        const k = 1024
        if (rawFile.size / k / k > 100) {
          ElMessage.error('The file size can not exceed 100m!')
          status = false
        }
        if (status) uploadFile.value = rawFile
      } else {
        status = false
      }
      return status
    })
    .then(() => {
      if (uploadFile.value) {
        uploadError.value = false
        showUpload.value = false
        analyze.value = false
      }
      return status
    })
}

const onUploadError: UploadProps['onError'] = (error) => {
  uploadError.value = true
  showUpload.value = false
  analyze.value = false
  const err = JSON.parse(error.message)
  ElMessage.error(err?.data?.message)
}

const onUploadSuccess: UploadProps['onSuccess'] = (response) => {
  analyze.value = true
  fileData.value = response.data
  if (uploadForm.type === 'GENERAL') {
    uploadForm.generalType = 'CHAIN'
  }
}

const onCancelUpload = () => {
  onReuploadFile()
  uploadRef.value?.abort()
}

const onDownloadTemplateFile = () => {
  downloadFile(`${currentCsvDriverTemplate.value?.downloadTemplateUrl}`)
}

const resetFrom = () => {
  onReuploadFile()
  uploadFormRef.value?.resetFields()
}

defineExpose({
  resetFrom
})

watchEffect(() => {
  onReuploadFile()
  uploadForm.name = ''
  uploadForm.file = ''
  uploadForm.entityAccountId = props.currentData?.entityAccountId
  if (props.currentData?.platform?.csvDriver === 'CUSTOM') {
    uploadForm.type = 'CUSTOM'
  } else {
    uploadForm.type =
      // eslint-disable-next-line no-nested-ternary
      props.currentData?.platform?.type === 'BANK'
        ? 'BANK'
        : props.currentData?.platform?.type === 'EXCHANGE'
          ? 'EXCHANGE_TRADE'
          : 'GENERAL'
  }
  uploadForm.slug = props.currentData?.platform?.csvDriverTemplateList[0]?.slug
})
</script>

<style lang="scss">
.elv-source-upload-tip {
  color: #636b75;
  font-family: 'Plus Jakarta Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;

  span {
    color: #1753eb;
    cursor: pointer;
    text-decoration-line: underline;
  }
}

.elv-source-upload-tips {
  padding: 0px;
  border: 1px solid #e4e7eb !important;
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.15),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04),
    0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}

.elv-source-upload-timezone {
  margin-top: 16px;
}

.elv-source-upload-tips-content {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e0f11;
  width: 218px;
  cursor: pointer;
}
</style>
